<template>
  <div class="p-root">
    <img :src="icons.intro_banner_en" class="full-width" />

    <div class="full-width column-center" style="padding: 50px 0">
      <!-- <span class="intro-title">简 介</span> -->
      <span class="intro-title">Introduction</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <!-- <span class="intro-red" style="font-size: 22px; margin-top: 30px">An international dialogue between art,
        literature and children</span>
      <span class="intro-red" style="font-size: 22px">A spiritual conversation across time, space, age and
        identity</span> -->

      <span class="intro-content-width intro-18" style="margin-top: 20px;text-align:justify">The first “Le Petit
        Maupassant” Cup
        international art co-creation tour, jointly sponsored by the World Federation of UNESCO Clubs, Centers and
        Associations (WFUCA) and Association pour le Développement des Échanges France-Chine (ADEFC), is an art exchange
        and interactive activity for international artists and children from China and other countries. 5 French artists
        were invited to present 5 “unfinished” works based on the Swan Castle and the childhood memories of Marcle
        Proust, one of the greatest French novelists in the history of world literature in the 20th century. Each work
        has been intentionally left “blank”. Children from China and other countries will jointly complete these 5
        “unfinished works” with their innocence and wild imagination to realize “co-creation” and start a magical
        artistic dialogue across time, space, regions and cultures.
      </span>
      <img :src="icons.introduction_foldi" class="intro-content-width" style="margin-top:10px" />
      <span class="intro-content-width" style="margin-top: 10px;text-align:center;font-size:14px">Artist Augusto FOLDI
        creats
        his art at the
        Castle Swan </span>
      <span class="intro-content-width intro-18" style="margin-top: 20px;text-align:justify">The works that pass the
        review will be
        eligible to participate in the tour exhibitions held at Louvre and the
        Swan Castle, France, and will be exhibited together with the finished works of the 5 invited artists.
      </span>
      <img :src="icons.introduction_ronel" class="intro-content-width" style="margin-top:10px" />
      <span class="intro-content-width" style="margin-top: 10px;text-align:center;font-size:14px">Artist Christophe
        RONEL creats his art at the Castle Swan</span>
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 0">
      <!-- <span class="intro-title">组织机构</span> -->
      <span class="intro-title">Organizations</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <!-- <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px"> Children aged 3-14 years are
        divided into five age groups</span>
      <span class="intro-black intro-18" style="margin-top: 10px">3-5 years, 6-7 years, 8-9 years, 10-12 years and 13-14
        years</span> -->
      <span class="intro-sub-title" style="margin-top: 30px">Initiators & Hosts</span>
      <div>
        <ul>
          <li class="intro-content-width intro-18">World Federation of UNESCO Clubs, Centers and Associations (WFUCA)
          </li>
          <li class="intro-content-width intro-18">Association pour le Développement des Échanges France-Chine (ADEFC)
          </li>
        </ul>
      </div>

      <span class="intro-sub-title" style="margin-top: 30px">Co-hosts</span>
      <div>
        <ul>
          <li class="intro-content-width intro-18">“Fashion Show”, a children’s program, Central Studio of News Reels
            Production,
            China Media Group</li>
          <li class="intro-content-width intro-18">The government of Centre-Val de Loire, France</li>
          <li class="intro-content-width intro-18">The government of Illiers-Combray, France</li>
          <li class="intro-content-width intro-18">French International Academy of Arts</li>
        </ul>
      </div>

      <span class="intro-sub-title" style="margin-top: 30px">Organizers</span>
      <div>
        <ul>
          <li class="intro-content-width intro-18">Paris Sense International Cultural Development (Beijing) Co., Ltd.
          </li>
          <li class="intro-content-width intro-18">Yidi Media</li>
          <li class="intro-content-width intro-18">France Boroi Culture Co., Ltd.</li>
        </ul>
      </div>
    </div>

    <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <!-- <span class="intro-title">报名方式</span> -->
      <span class="intro-title">Registration</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />
      <span class="intro-content-width intro-18" style="font-size: 18px; font-weight: bold; margin-top: 30px">If you
        want to
        register for this event, please
        contact us by e-mail or telephone.</span>

      <span class="intro-content-width intro-18 contact-title" style="margin-top: 50px;text-align:center">Frane
        Office:</span>
      <span class="intro-content-width intro-18 contact-title" style="margin-top: 20px">ADD：</span>
      <span class="intro-content-width intro-18 contact-label3">27 Avenue Bugeaud,75016 Paris,France</span>
      <span class="intro-content-width intro-18 contact-title" style="margin-top: 20px">E-mail：</span>
      <span class="intro-content-width intro-18 contact-label3">Christine@chateaudeswann.com</span>
      <span class="intro-content-width intro-18 contact-title" style="margin-top: 20px">TEL：</span>
      <span class="intro-content-width intro-18 contact-label3">Xueling Li:+33768669707</span>
      <span class="intro-content-width intro-18 contact-label3">Jia Li:+33783650806</span>

      <span class="intro-content-width intro-18 contact-title" style="margin-top:50px;text-align:center">Chinese
        Office:</span>
      <span class="intro-content-width intro-18 contact-title" style="margin-top: 20px">ADD：</span>
      <span class="intro-content-width intro-18 contact-label3">Room 210,Hanhai International Building,No.13 Jiuxianqiao
        Road,Chaoyang
        District,Beijing,China</span>
      <span class="intro-content-width intro-18 contact-title" style="margin-top: 20px">E-mail：</span>
      <span class="intro-content-width intro-18 contact-label3">ysld2021@126.com</span>
      <span class="intro-content-width intro-18 contact-label3">ysld2021@qq.com</span>
      <span class="intro-content-width intro-18 contact-title" style="margin-top: 20px">TEL：</span>
      <span class="intro-content-width intro-18 contact-label3">Miss Anna:+86-13041129509</span>
      <span class="intro-content-width intro-18 contact-label3">Miss Sophnie:+86-15611824870</span>
    </div>
  </div>
</template>

<script>
import * as eventHelper from "@/utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
  methods: {
    download1() {},
    download2() {},
    goArtists() {
      // this.$router.push({
      //   name: "artists",
      // });
      this.$router.push({
        name: "create",
      });
    },
    goMilk() {
      window.open(
        "https://shop92949538.m.youzan.com/v2/showcase/homepage?alias=eC6Gfn4IzM&dc_ps=2841760543432437765.300001"
      );
    },
  },
};
</script>

<style scoped>
.intro-content-width {
  width: 800px;
}
.divide {
  background-color: #ffffff;
  height: 1px;
  width: 500px;
  opacity: 0.5;
}
.intro-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
  font-weight: bold;
}
.intro-sub-title {
  font-family: PingFangSC-Semibold;
  font-size: 22px;
  letter-spacing: 0;
  text-align: center;
  font-weight: bold;
}
.intro-title-white {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 35px;
}
.intro-title-blue {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #062c3e;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white-24 {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-blue {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 44px;
}
.intro-title-yellow {
  font-family: ZhenyanGB-Regular;
  font-size: 30px;
  color: #f7eb50;
  letter-spacing: 0;
}
.intro-btn {
  cursor: pointer;
  background: #062c3e;
  border-radius: 8px;
  padding: 0 30px;
  height: 65px;
  align-self: flex-start;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 65px;
}

.intro-red {
  color: #f72e02;
}
.intro-blue {
  color: #0088cd;
}
.intro-black {
  color: #000000;
}

.intro-18 {
  font-size: 18px;
}
.contact-title {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
}
.contact-label3 {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  letter-spacing: 0;
}
</style>